<template>
	<main role="main" class="read-s">
		<!-- <img src="../../assets/img/xintp/sybj.png" alt=""> -->
		<img src="../../assets/img/xintp/banner.jpg" alt="" style="width: 100%; height: auto" />
		<div class="ztnrbg">
			<div class="index_mv ztnr">
				<leftMeun :name="name || names" :menuid="id"></leftMeun>
				<div class="right_nr">
					<div class="category_news_article" style="border: 0; margin-bottom: 0">
						<rightMeun :name="name || names" :menuid="id" v-if="catid != 120"></rightMeun>
					</div>
					<!--  外链样式  -->
					<div class="web-link" v-if="currentMenu && currentMenu.type == 'link'">
						<a :href="currentMenu.jump" class="link-title" :title="name"
							>{{ name }} <Icon type="md-arrow-forward"
						/></a>
					</div>
					<div v-else-if="catid == 160"></div>
					<div v-else>
						<p style="text-align: right" v-if="data.content">
							字号：<a @click="SetFont1(16)">大</a> <a @click="SetFont1(14)">中</a> <a @click="SetFont1(12)">小</a>
						</p>
						<article class="category_news_article" style="border: 0; margin-bottom: 0">
							<!-- <div class="mbx notice_article"><a :href="href1">{{data.fname}}</a> / <a :href="href2">{{data.catname}}</a></div> -->
							<section class="read-s_article">
								<!-- <div class="read-s_article_mv" :style="'background-image: url('+img+');'">

                            <p class="read-s_article_mv_ttl typesquare_option">{{data.title}}</p>
                            <span class="read-s_article_mv_ttl_sub"></span>
                          </div> -->
								<div class="read-s_article_head" style="width: 100%; margin-left: 0%; margin-top: 0">
									<div class="read-s_article_content" v-if="data.content">
										<div class="newsTit">
											{{ data.title }}
											<p style="text-align: center">
												发表于：{{ data.timeline }} 浏览次数：{{ data.browse }}次
											</p>
										</div>
									</div>
								</div>
								<div id="body_data" style="margin-top: 30px；" v-html="data.content">
									<!-- {{data.content}} -->
								</div>
								<div
									class=" read-s_article_content scrolled"
									v-if="index == 2 || catid == 408 || catid == 411"
								>
									<div v-for="(li, index1) in data.files" :key="index1">
										<div v-if="li.pdf">
											<iframe :src="li.file_url" width="100%" height="900"> </iframe>
										</div>
										<!-- <div>
										<a :href="li.path" target="_blank">
											<div>{{ li.name }}</div>
										</a>
										<a :href="li.path" target="_blank" v-if="li.type == 'url'">点击浏览</a>
										<a :href="li.path" v-else>点击下载</a>
									</div> -->
									</div>
								</div>
								<div class="filesL read-s_article_content scrolled">
									<div class="download" v-for="(li, index1) in data.files" :key="index1" >
										<a :href="li.path" target="_blank"  class="a-left">
											<div  style="font-weight: bold;font-size:16px;">{{ li.name }}</div>
										</a>
										
										<a :href="li.path" target="_blank" v-if="li.type == 'url'" class="a-right" >点击浏览</a>
										<a :href="li.path" v-else class="a-right">点击下载</a>
									</div>
								</div>
							</section>
							<!-- <div class="read-s_pagenation">
                              <a class="read-s_pagenation_a js-parallax scrolled" href="#"><span>返回顶部</span></a>
                            </div> -->
						</article>
						<!-- <div class="notice_banners" style="padding-top:0px;padding-bottom:51px;">
                          <div class="list">
                            <div class="read_articles" style="border:0">
                              <Row type="flex" justify="start" align="top" style="width:100%;">
                                <i-col :xs="24" :sm="12" :lg="8" v-for="(li,index) in tuij" :key='index' class-name="flexcenter">
                                  <list :msg="li" />
                                </i-col>
                              </Row>
                            </div>



                          </div>
                        </div> -->

						<!-- <div v-else class="content_wrapper">
							v-if="index != 1"
										class="read-s_article_content scrolled"
							<div v-if="data.content" v-html="data.content"></div>
							<img v-if="data.thumb" :src="data.thumb" :key="index" alt="" />
						</div> -->
					</div>
				</div>
				<newRightMeun :index="index"></newRightMeun>
			</div>
		</div>
	</main>
</template>
<script>
var _hmt = _hmt || [];
(function () {
	var hm = document.createElement('script');
	hm.src = 'https://hm.baidu.com/hm.js?819d608e55a54ab96615bb5d06bb6230';
	var s = document.getElementsByTagName('script')[0];
	s.parentNode.insertBefore(hm, s);
})();
</script>
<script>
import list from '@/components/list.vue';
import leftMeun from '@/components/leftMeun.vue';
import newRightMeun from '@/components/newRightMeun.vue';
import rightMeun from '@/components/rightMeun.vue';
import $ from 'jquery';
export default {
	components: {
		list,
		leftMeun,
		rightMeun,
		newRightMeun,
	},
	data() {
		return {
			id: this.$route.query.id,
			name: '',
			data: {
				content: '',
			},
			tuij: [],
			href1: '',
			href2: '',
			index: this.$route.query.index,
			names: this.$route.query.names,
			isShowTtile: 0,
			catid: this.$route.query.catid,
			//栏目信息
			currentMenu: {},
			childrenMenu: null,
		};
	},
	watch: {
		$route: {
			handler: function (val) {
				let { navList, navIndexs } = this.$store.state;
				let tow = navList[navIndexs[0]].children[navIndexs[1]];
				let three = {};
				this.childrenMenu = null;
				if (tow.children) {
					three = tow.children;
					// console.warn(three);
					if (three[navIndexs[2]].children) {
						this.childrenMenu = three[navIndexs[2]].children;
					}
					// console.error(three, navIndexs[2], three[navIndexs[2]].children);
				}

				this.currentMenu = three;
				this.types();
			},
			// 深度观察监听
			deep: true,
		},
	},
	methods: {
		types() {
			// console.log('======================', this.$route);
			let { query, params } = this.$route;
			this.catid = params.newsid;
			// console.log(query, params);
			//console.log('roter---------------->', this.$route);
			this.isShowTtile = query.show_tile;
			//console.log(' params.show_tile', this.isShowTtile);
			if (query.page == 1) {
				this.$api
					.page({ id: query.catid })
					.then((res) => {
						this.$emit('getLoad', true);
						new Promise((resolve, reject) => {
							if (res.code == 1 && res.data) {
								resolve(res.data);
							} else {
								reject();
							}
						})
							.then((data) => {
								this.data = data;
								let _setFont = (ms) => {
									ms += 100;
									setTimeout(() => {
										this.SetFont1(14);
										if (ms < 600) {
											_setFont();
										}
									}, ms);
								};
								_setFont();
							})
							.catch((e) => {
								this.data = {
									content: '暂无信息',
								};
							});
					})
					.catch((error) => {
						// error
						console.log(error);
					});
			} else {
				this.$api
					.detai({
						id: params.newsid,
					})
					.then((res) => {
						this.$emit('getLoad', true);
						new Promise((resolve, reject) => {
							if (res.code == 1 && res.data) {
								resolve(res.data);
							} else {
								reject();
							}
						})
							.then((data) => {
								this.data = res.data;
								let _setFont = (ms) => {
									ms += 100;
									setTimeout(() => {
										this.SetFont1(14);
										if (ms < 600) {
											_setFont();
										}
									}, ms);
								};
								_setFont();
							})
							.catch((e) => {
								this.data = '暂无介绍';
							});
					})
					.catch((error) => {
						// error
						console.log(error);
					});
				this.$api
					.tuijan({
						id: this.$route.params.newsid,
					})
					.then((res) => {
						// success
						this.tuij = res.data;
						//console.log(res);
					})
					.catch((error) => {
						// error
						console.log(error);
					});
			}
		},
		SetFont1(size) {
			$('#body_data *').css({ fontSize: size + 'px', fontFamily: '宋体', lineHeight: 2 });
		},
	},
	mounted() {
		var that = this;
		that.types();
	},
};
</script>

<style>
.mbx {
	margin-top: 20px;
	margin-bottom: 0;
	font-size: 15px;
}

.filesL div a {
	margin: 0 0 0 20px;
}

.filesL div div {
	flex: 1;
}

.filesL > div {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 5px 0;
	border-bottom: 1px solid #eee;
}
.web-link {
	width: 100%;
	position: relative;
	height: 180px;
	background: #f5f5f5;
}
.web-link .link-title {
	color: #fff;
	background: #26b6b4;
	height: 30px;
	width: 150px;
	display: inline-block;
	position: absolute;
	left: 50%;
	top: 50%;
	margin-left: -75px;
	margin-top: -15px;
	text-align: center;
	line-height: 30px;
}

img {
	max-width: 100%;
	height: auto;
}
.download{
font-weight: bold;font-size:16px;

}
.download .a-left{
width: 78%;
}
.download .a-right{
width:21%;
}
.filesL div a {
    margin: 0;
}
</style>
